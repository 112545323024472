// src/App.js

import React, { useState } from 'react';
import './App.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

function App() {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleJoinWaitlist = (e) => {
    e.preventDefault();
    // For now, simply display a success message
    setSuccess(true);
    setEmail('');

    // Optionally, reset the success message after a few seconds
    setTimeout(() => setSuccess(false), 5000);
  };

  return (
    <div className="App">
      <div className="background"></div>
      <header className="App-header">
        <div className="container">
          <h1>Welcome to TalkHere.AI</h1>
          <p>
            We build a language learning app that empowers people around the world to learn languages and gain opportunities.
          </p>
          <blockquote>
            "We are developing the app right now and will notify you when it's published in the app store."
            <br />
          </blockquote>
          <form className="waitlist-form" onSubmit={handleJoinWaitlist}>
            <input
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit">Join Waitlist</button>
          </form>
          {success && <p className="success-message">Thank you! You've joined the waitlist.</p>}

          {/* Support Email Section */}
          <div className="support-email">
            <blockquote>
              Support Email: jack.yifei.wang@gmail.com
              <br />
            </blockquote>
          </div>
        </div>
      </header>

      <div className="privacy-policy container">
        <h2>Privacy Policy</h2>
        <p>Last Updated: Oct 15, 2024</p>

        <h3>1. Introduction</h3>
        <p>
          At TalkHere.AI, we value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you use our mobile application, website, and related services (the 'Service').
        </p>

        <h3>2. Information We Collect</h3>
        <h4>2.1 Personal Information</h4>
        <p>
          We collect personal information such as your name, email address, and phone number when you register for the Service.
        </p>

        <h3>3. How We Use Your Information</h3>
        <p>
          We use your information to:
        </p>
        <ul>
          <li>identify you when you sign up and sign in</li>
        </ul>

        <h3>4. Sharing of Information</h3>
        <p>
          We do not share your personal information with third parties except:
        </p>
        <ul>
          <li>With service providers who assist us in operating the Service, under strict confidentiality agreements</li>
          <li>If required by law, such as to comply with a legal obligation or in response to a court order</li>
        </ul>

        <h3>5. International Data Transfers</h3>
        <p>
          If you are located outside the United States, your personal information may be transferred to, and processed in, the United States and other countries. We ensure that such transfers are compliant with GDPR and other applicable data protection laws.
        </p>

        <h3>6. Data Security</h3>
        <p>
          We implement reasonable security measures to protect your data from unauthorized access, alteration, or disclosure. However, no system is completely secure, and we cannot guarantee the absolute security of your information.
        </p>

        <h3>7. Your Rights</h3>
        <h4>7.1 GDPR (EU) Rights</h4>
        <p>
          If you are a resident of the European Union, you have the right to:
        </p>
        <ul>
          <li>Access your personal data</li>
          <li>Request correction or deletion of your data</li>
          <li>Object to the processing of your data</li>
          <li>Withdraw consent at any time</li>
        </ul>

        <h4>7.2 CCPA (California) Rights</h4>
        <p>
          If you are a resident of California, you have rights under the California Consumer Privacy Act (CCPA), including the right to:
        </p>
        <ul>
          <li>Know what personal information is being collected, used, shared, or sold.</li>
          <li>Access and request a copy of the personal information we have collected about you in the past 12 months.</li>
          <li>Delete personal information collected from you, subject to certain exceptions.</li>
          <li>Opt-Out of the sale of your personal information. Note: We do not sell your personal information.</li>
          <li>Non-Discrimination for exercising your CCPA rights.</li>
        </ul>

        <h3>8. Data Retention</h3>
        <p>
          We will retain your personal information for as long as necessary to provide the Service or as required by law. You may request the deletion of your data at any time.
        </p>

        <h3>9. Children’s Privacy</h3>
        <p>
          The Service is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we learn that we have collected such information, we will take steps to delete it.
        </p>

        <h3>10. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. When we do, we will revise the 'Last Updated' date at the top of this page. We encourage you to review this Policy periodically.
        </p>

        <h3>11. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact us at:
        </p>
        <p>
          - Email: yifei.jack.wang@gmail.com<br />
          - Address: 70 Columbus Dr, Jersey City, NJ 07302, United States.
        </p>
      </div>

      {/* Terms of Service Section */}
      <div className="terms-of-service container">
        <h2>Terms of Service</h2>
        <p>Last Updated: October 15, 2024</p>

        <h3>1. Acceptance of Terms</h3>
        <p>
          By using our services, including our mobile application, website, and associated features (the 'Service'), you agree to be bound by these Terms of Service. If you do not agree to these terms, you must not use our Service. Your continued use of the Service constitutes your acceptance of these Terms.
        </p>

        <h3>2. Eligibility</h3>
        <p>
          You must be at least 18 years of age or the age of majority in your jurisdiction to use our Service. By using the Service, you represent that you are legally able to enter into a binding contract.
        </p>

        <h3>3. Service Overview</h3>
        <p>
          The Service is designed to provide news and AI translations for language learning.
        </p>

        <h3>4. User Accounts</h3>
        <p>
          To use certain features of the Service, you must create an account. You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorized use of your account.
        </p>

        <h3>5. Use of the Service</h3>
        <p>
          You agree to use the Service in accordance with all applicable laws and regulations. You may not use the Service to engage in any activity that is harmful to others, violates privacy laws, or infringes on the rights of others.
        </p>

        <h3>6. Data Collection and Processing</h3>
        <p>
          By using the Service, you consent to the collection, use, and processing of your personal data as outlined in our Privacy Policy.
        </p>

        <h3>7. Intellectual Property</h3>
        <p>
          All content, features, and functionality of the Service, including text, graphics, logos, and software, are the property of TalkHere.AI or its licensors and are protected by intellectual property laws.
        </p>

        <h3>8. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, TalkHere.AI will not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service, including any loss of data, injury, or other consequences resulting from the use of news and AI translations.
        </p>

        <h3>9. Termination</h3>
        <p>
          We reserve the right to terminate or suspend your access to the Service at any time for conduct that we believe violates these Terms or is harmful to other users.
        </p>

        <h3>10. Governing Law</h3>
        <p>
          These Terms will be governed by and construed in accordance with the laws of Delaware, United States. Any disputes arising out of or relating to these Terms shall be resolved through binding arbitration.
        </p>
      </div>

      <footer>
        <div className="contact-info container">
        </div>
      </footer>
    </div>
  );
}

export default App;
